import type { Component } from "vue"

type FlashMessage = {
  text?: string
  position?: "top" | "bottom"
  icon?: Component | string
  iconClass?: string
}

export const useFlashMessage = () => {
  const message = useState<FlashMessage>(() => {
    return { position: "bottom" } as const
  })

  return { message }
}
